import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useClientSide } from '../../hooks/useClientSide';
import { useEventListener } from '../../hooks/useEventListener';

const Cursor = () => {
  const cursorRef = useRef(null);
  const isClient = useClientSide();

  const handleCursor = (e) => {
    cursorRef.current.style.left = `${e.pageX}px`;
    cursorRef.current.style.top = `${e.pageY}px`;
  };
  useEventListener('mousemove', handleCursor, isClient && window);

  const [hover, setHover] = useState(false);

  useEffect(() => {
    const buttons = isClient && document.querySelectorAll('a');

    buttons.forEach((button) => {
      button.addEventListener('mouseover', () => setHover(true));
      button.addEventListener('mouseleave', () => setHover(false));
    });

    return () =>
      buttons.forEach((button) => {
        button.removeEventListener('mouseover', () => setHover(true));
        button.removeEventListener('mouseleave', () => setHover(false));
      });
  });

  return (
    <StyledCursor
      ref={cursorRef}
      className={hover ? 'cursor-hover' : undefined}
    />
  );
};

const StyledCursor = styled.span`
  width: 8px;
  height: 8px;
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  background: white;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
  transition: width 0.3s ease;

  &.cursor-hover {
    width: 24px;
    height: 24px;
  }
`;

export default Cursor;
