import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import Tilt from 'react-tilt';
import Parallax from '../atoms/parallax';
import { media } from '../../utils/media-queries';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Sketch } from '../atoms/sketch';
import { loadableP5 as P5Wrapper } from '../../utils/loadable';

const Project = ({ imgSrc, imgAlt, copy, className }) => {
  const { height, width } = useWindowSize();

  const ref = useRef();
  const [projectSize, setProjectSize] = useState({
    projectHeight: 0,
    projectWidth: 0,
  });

  useEffect(() => {
    setProjectSize({
      projectHeight: ref.current.getBoundingClientRect().height,
      projectWidth: ref.current.getBoundingClientRect().width,
    });
  }, [height, width]);

  return (
    <StyledProject ref={ref}>
      <StyledCanvas>
        <P5Wrapper
          sketch={Sketch(projectSize.projectWidth, projectSize.projectHeight)}
        />
      </StyledCanvas>
      <Tilt
        className={`grid inner ${className} spacing-inner`}
        options={{ max: 12, scale: 1 }}
      >
        <div className="img">
          <figure>
            <Img fluid={imgSrc} alt={imgAlt} />
          </figure>
        </div>
        <Parallax speed={100} className="copy">
          <p>{copy}</p>
        </Parallax>
      </Tilt>
    </StyledProject>
  );
};

const StyledCanvas = styled.div`
  position: absolute;
  top: 0;
  display: none;

  @media ${media.M} {
    display: block;
  }
`;

const StyledProject = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;

  .inner {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
  }

  .img,
  .copy {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .copy {
    display: flex;
    align-items: center;
    z-index: 1;
  }

  @media ${media.M} {
    height: 120vh;
  }
`;

Project.propTypes = {
  imgSrc: PropTypes.object,
  imgAlt: PropTypes.string,
  copy: PropTypes.string,
  className: PropTypes.string,
};

export default Project;
