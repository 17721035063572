/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

function encode(data) {
  const formData = new FormData();

  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  return formData;
}

const GatsbyForm = () => {
  const [state, setState] = useState({});
  const [fileChosen, setFileChosen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleAttachment = (e) => {
    setState({ ...state, [e.target.name]: e.target.files[0] });
    setFileChosen(!fileChosen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  return (
    <StyledGatsbyForm
      name="send-screenshot"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/"
      onSubmit={handleSubmit}
      className="grid"
    >
      <p>{submitted ? 'Thank you!' : 'Send me a screenshot of your doodle!'}</p>
      <fieldset>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="send-screenshot" />
        <p hidden style={{ display: 'none' }}>
          <label htmlFor="bot-field">
            Don’t fill this out:
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <div className="upload-btn">
          <button type="button" className="spacing">
            {fileChosen ? 'Screenshot uploaded' : 'Upload screenshot'}
          </button>
          <input type="file" name="attachment" onChange={handleAttachment} />
        </div>
        <div className="message">
          <label htmlFor="message">Message</label>
          <textarea
            className="spacing"
            name="message"
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="name">
          <label htmlFor="name">From</label>
          <input
            className="spacing"
            type="text"
            name="name"
            onChange={handleChange}
          />
        </div>
        <div className="button">
          <button
            type="submit"
            disabled={fileChosen === false || submitted === true}
            onClick={() => setSubmitted(true)}
          >
            Send
          </button>
        </div>
      </fieldset>
    </StyledGatsbyForm>
  );
};

const StyledGatsbyForm = styled.form`
  padding: calc(var(--spacing-XL) * 3) var(--v-spacing-M);
  cursor: default;

  p {
    grid-column: 3 / 7;
  }

  fieldset {
    grid-column: 7 / 11;
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }

  .name,
  .message {
    font-size: var(--copy-small);
    padding-bottom: var(--spacing-XS);

    input,
    textarea {
      margin-top: var(--spacing-XXXS);
      border: 2px solid white;
      font-family: var(--font-1);
      color: white;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .upload-btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding-bottom: var(--spacing-XXS);

    button {
      border: 2px solid white;
      border-radius: 2px;
      text-align: left;
    }
  }

  .spacing {
    padding-left: var(--spacing-XS);
    padding-right: var(--spacing-XS);
    padding-top: var(--spacing-XXS);
    padding-bottom: calc(var(--spacing-XXS) / 1.7);
    border-radius: 2px;
  }

  input,
  textarea {
    width: 100%;
    display: inline-block;
  }
`;

export default GatsbyForm;
