export function Sketch(width, height) {
  return (p) => {
    p.setup = () => {
      p.createCanvas(width, height);
      p.noStroke();
    };

    p.draw = function () {
      p.strokeWeight(6);
      p.stroke('#fff');
      if (p.mouseIsPressed) {
        p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY);
      }
    };
  };
}
