import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useConsole } from '../hooks/useConsole';
import SEO from '../components/organisms/seo';
import Layout from '../components/organisms/layout';
import Project from '../components/molecules/project';
import Cursor from '../components/atoms/cursor';
import Form from '../components/molecules/form';
import { media } from '../utils/media-queries';

const IndexPage = ({ data }) => {
  useConsole();

  const { content } = data.homepage.nodes[0];

  return (
    <Layout>
      <SEO />
      <Cursor />
      {content.map((project, i) => (
        <Project
          key={i}
          className={project.position}
          imgAlt={project.image.alt}
          imgSrc={project.image.image.asset.fluid}
          copy={project.copy}
        />
      ))}
      <StyledForm>
        <Form />
      </StyledForm>
    </Layout>
  );
};

const StyledForm = styled.section`
  display: none;

  @media ${media.M} {
    display: block;
  }
`;

export const query = graphql`
  query Homepage {
    homepage: allSanityHomepage {
      nodes {
        content {
          image {
            image {
              asset {
                fluid(maxWidth: 3600) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            alt
          }
          copy
          position
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
